
import { Component, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import { mixins } from 'vue-class-component';
import tasqsListModule from '@/store/modules/tasqsListModule';
import padSignalsModule from '@/store/modules/padSignalsModule';
import { getComponent, getConfigEnv, sleep } from '@/utils/helpers';
import DataLoading from '@/lib/mixins/dataLoading';
import assetsModule from '@/store/modules/assetsModule';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import GenericMixin from '@/lib/mixins/GenericMixin';
import workflowModule from '@/store/modules/workflowModule';
import userPreference from '@/lib/userPreference';

@Component({
  components: {
    TasqLoader: () => getComponent('loaders/TasqLoader'),
    PadSignalViewChart: () => getComponent('tasqs/PadSignalViewChart'),
    ChartTimeRange: () => getComponent('tasqs/charts/ChartTimeRange'),
  },
})
export default class WellSignalsView extends mixins(DataLoading, GenericMixin) {
  chartTimes: any[] = this.$getConst('CHART_TIMES');

  signalChartTime = 60;

  chartRefreshKey = 0;

  hideChartTimes = false;

  showFullScreenSignalsChart = false;

  isLoadingCompressorSignals = true;

  isLoadingPadProductionSignals = true;

  isLoadingPadSignals = true;

  isLoadingAggregateSignals = true;

  chartsLoading = false;

  get tasqListLevel() {
    if (
      tasqsListModule.tasqListLevel.toLowerCase() == 'well' ||
      (this.activeTasq &&
        tasqsListModule.tasqListLevel.toLowerCase() !== 'wellview' &&
        this.activeTasq.level.toLowerCase() == 'well')
    ) {
      return 'Well';
    }
    return tasqsListModule.tasqListLevel.toLowerCase();
  }

  get activeTasq(): any {
    if (tasqsListModule.tasqListLevel.toLowerCase() === 'workticket') {
      console.log(tasqsListModule.activeWorkTicket);
      return tasqsListModule.activeWorkTicket;
    }
    if (this.isEditing || tasqsListModule.checkedTasqs.length) {
      if (!tasqsListModule.activeTasq) {
        tasqsListModule.setActiveTasq(tasqsListModule.checkedTasqs[0]);
      }
      return tasqsListModule.activeTasq as TasqJob;
    }
    if (this.$route.query.type == 'id' && this.$route.params.id != null) {
      assetsModule.setActiveProducingTasq('');
      return tasqsListModule.activeTasq as TasqJob;
    }
    if (
      tasqsListModule.activeTasq != null &&
      this.$route.params.id != null &&
      this.$route.query.type == 'producing' &&
      tasqsListModule.activeTasq.level.toLowerCase() == 'pad'
    ) {
      return tasqsListModule.activeTasq;
    }
    if (assetsModule.activeTasq == undefined) {
      return tasqsListModule.activeTasq as TasqJob;
    }
    return assetsModule.activeTasq as TasqJob;
  }

  get isEditing() {
    return tasqsListModule.isEditing;
  }

  get currentWellType(): any {
    return this.$route.query.type;
  }



  get padSignals(){
    return padSignalsModule.padSignals.length
  }


  get compressorSignals(){
    return padSignalsModule.compressorSignals.length
  }



  showChartByFilter(filter): any {
    return padSignalsModule[`${filter}Signals`].length > 1 ? true : false;
  }

  async changeChartTimes(val) {
    await userPreference.setItem('signalChartTime',val)
    this.hideChartTimes = true;
    this.signalChartTime = val;
    tasqsListModule.setSignalChartTime(val);
    await this.querySignalData();
  }

  async fetchPadSignals() {
    this.chartTimes = this.$getConst('CHART_TIMES');
    let storedSignalChartTime: any = await userPreference.getItem('signalChartTime');
    if(storedSignalChartTime){
      this.signalChartTime =  storedSignalChartTime;
    }else{
      this.signalChartTime = 60;
    }
    tasqsListModule.setFromNowBack(0);
    await this.querySignalData();
    // await workflowModule.getWellStatuses(this.activeTasq?.wellName)
  }


  async loadPadSignalsData(selectedSignals){
    const padSignalsPromise: any[] = [];
    this.isLoadingPadSignals = true;
    await sleep(100);
    padSignalsPromise.push(
      padSignalsModule.getSignalsForPad({
        data: this.signalChartTime,
        filter: 'pad',
        loadData: true,
        fetchSelectedSignals:selectedSignals
      })
    );

    Promise.all(padSignalsPromise).then(
      async () => {
        await sleep(5000);
        this.isLoadingPadSignals = false;
      },
      (err) => {
        console.error(
          `Error: Failed to signal  data on ${
            this.activeTasq ? this.activeTasq.wellName : ''
          }, for ${this.signalChartTime} days`
        );
        console.log('Error:');
        console.log(err);
      }
    );
  }


  async loadCompressorSignalsData(selectedSignals){
    const compressorPromises: any[] = [];
    this.isLoadingCompressorSignals = true;
    await sleep(100);
    compressorPromises.push(
      padSignalsModule.getSignalsForPad({
        data: this.signalChartTime,
        filter: 'compressor',
        loadData: true,
        fetchSelectedSignals:selectedSignals
      })
    );

    Promise.all(compressorPromises).then(
      async () => {
        await sleep(5000);
        this.isLoadingCompressorSignals = false;
      },
      (err) => {
        console.error(
          `Error: Failed to signal  data on ${
            this.activeTasq ? this.activeTasq.wellName : ''
          }, for ${this.signalChartTime} days`
        );
        console.log('Error:');
        console.log(err);
      }
    );
  }

  async querySignalData() {

  this.hideChartTimes = true;
  this.isLoadingAggregateSignals = true;
  this.isLoadingPadSignals = true;
  this.isLoadingCompressorSignals = true;
  this.isLoadingPadProductionSignals = true;







  try {
    await    padSignalsModule.getSignalsForPad({
    data: this.signalChartTime,
    filter: 'aggregate',
  });
  await sleep(2000);
  this.isLoadingAggregateSignals = false;
  } catch (error) {

  }

  const productionPromises = padSignalsModule.getPadWellProductionData({
    data: this.signalChartTime,
    tasq: this.activeTasq,
  });


  const padSignalsPromise = padSignalsModule.getSignalsForPad({
    data: this.signalChartTime,
    filter: 'pad',
  });
  const compressorPromises = padSignalsModule.getSignalsForPad({
    data: this.signalChartTime,
    filter: 'compressor',
  });


  try {
    await Promise.all([

      productionPromises,
      padSignalsPromise,
      compressorPromises

    ]);
    await sleep(2000);
    this.hideChartTimes = false;
    this.isLoadingPadProductionSignals = false;
  } catch (error) {
    console.error(`Error: Failed to signal data on ${this.activeTasq ? this.activeTasq.wellName : ''}, for ${this.signalChartTime} days`);
    console.log('Error:');
    console.log(error);
  }

  await sleep(2000);
  this.isLoadingAggregateSignals = false;

  await sleep(2000);
  this.isLoadingPadSignals = false;
  await sleep(2000);
  this.isLoadingCompressorSignals = false;

}


  async created() {
    await sleep(2000);
    await this.fetchPadSignals();
  }
}
